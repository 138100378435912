import { nanoid, customAlphabet } from "nanoid";

const nanoIdNumber = customAlphabet('1234567890', 10)

export function generateId(size: number) {
  return nanoid(size);
}

export function generateNumberId(size: number) {
  return nanoIdNumber(size);
}
